import { Button, Col, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState, useContext } from "react";
import axios from "axios";
import { useRouter } from "next/router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { GlobalContext } from "./context/Global";

interface ContactFormProps {
  type?: string;
}
const customStyles = {
  control: (styles: any, { isFocused, isHovered }: any) => ({
    ...styles,
    borderRadius: 0,
    backgroundColor: "white",
    borderStyle: isFocused ? "none" : "none",
    boxShadow: isFocused ? "none" : "none",
    borderBottom: isFocused ? "1px solid #080a0e" : "1px solid #080a0e",
    background: "transparent",
    color: "#8c8c8c",
    "&:hover": {
      borderBottom: "1px solid #080a0e",
    },
    "@media (max-width: 1360px)": {
      fontSize: "12px",
    },
  }),
  singleValue: (styles: any, { isFocused }: any) => ({
    ...styles,
    width: "100%",
    background: "transparent",
    color: "#8c8c8c",
    fontSize: "17px",
    // paddingBottom: '0.71rem',
    "@media (max-width: 1360px)": {
      fontSize: "12px",
    },
    "@include media-breakpoint-up(xxl)": {
      fontSize: "1.0625rem",
    },
  }),
  placeholder: (styles: any, { isFocused }: any) => ({
    ...styles,
    width: "100%",
    background: "transparent",
    color: isFocused ? "#8c8c8c" : "#8c8c8c",
    fontSize: "17px",
    "@media (max-width: 1360px)": {
      fontSize: "12px",
    },
    "@include media-breakpoint-up(xxl)": {
      fontSize: "1.0625rem",
    },
    "@-moz-document url-prefix()": {
      color: "#c4b5b5" /* Adjust the color to match your design */,
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    padding: "0px",
  }),
  option: (styles: any, { isFocused }: any) => ({
    ...styles,
    width: "100%",
    background: "transparent",
    color: "#8c8c8c",
    fontSize: "17px",
    "@media (max-width: 1360px)": {
      fontSize: "12px",
    },
    "@include media-breakpoint-up(xxl)": {
      fontSize: "1.0625rem",
    },
  }),
};

const ContactForm = ({ type }: ContactFormProps) => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [min, setMin] = useState<any>(0);
  const { usercountryCode, country } = useContext<any>(GlobalContext);
  const options = [
    { value: "Rs.5,00,000 - Rs.15,00,000", label: "₹5,00,000 - ₹15,00,000" },
    { value: "Rs.15,00,000 - Rs.25,00,000", label: " ₹15,00,000 - ₹25,00,000" },
    { value: "Above Rs.25,00,000", label: "Above ₹25,00,000" },
  ];

  const inputStyle: React.CSSProperties = {
    borderStyle: isFocused ? "none" : "none",
    boxShadow: isFocused ? "none" : "none",
    borderBottom: isFocused ? "1px solid #080a0e" : "1px solid #080a0e",
    borderRadius: 0,
    width: "100%",
  };
  const RegisterSchema = Yup.object().shape({
    FirstName: Yup.string().required("Required"),
    // LastName: Yup.string().required("Required"),
    Email: Yup.string().email("Invalid email").required("Required"),
    // Phone: Yup.string().test(
    //   "is-number",
    //   "Invalid phone number",
    //   (val: any) => !isNaN(val)
    // ),
    // Phone: Yup.string().min(min, `${min} digits required`),
    Message: Yup.string().required("Required"),
  });

  const formik:any = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: "",
      Company: "",
      Message: "",
      Newsletter: false,
      phCountryCode: "",
      countryName: country,
      Budget: "",
      SubmittedFrom: "contactus"
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values: any) => {
      setLoading(true);
      if (values?.phCountryCode) {
        delete values.phCountryCode;
      }

      if (values?.countryName) {
        delete values.countryName;
      }
      Object.keys(values).forEach((k) => {
        if (!values[k]) {
          values[k] = null;
        }
      });
      await axios
        .post(
          `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/api/contact-uses`,
          { data: values },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_TOKEN}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);

          if (res?.data?.data?.id) {
            router.push(
              `/thank-you?submissionGuid=&submissionGuid=${res?.data?.data?.id}`
            );
          }
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });
  return (
    <div
      className={
        type === "contact" ? "contact-form home-contact" : "contact-form"
      }
    >
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={6} className="first-half">
            <p>
              <input
                type="text"
                name="FirstName"
                placeholder="First name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.FirstName}
              />
              {formik.errors.FirstName && (
                <div className="invalid-feedback d-block">
                  {formik.errors.FirstName}
                </div>
              )}
            </p>
          </Col>
          <Col md={6} className="second-half">
            <p>
              <input
                type="text"
                name="LastName"
                placeholder="Last name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.LastName}
              />
              {formik.errors.LastName && (
                <div className="invalid-feedback d-block">
                  {formik.errors.LastName}
                </div>
              )}
            </p>
          </Col>
          <Col md={6} className="first-half">
            <p>
              <input
                type="text"
                name="Email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Email}
              />
              {formik.errors.Email && (
                <div className="invalid-feedback d-block">
                  {formik.errors.Email}
                </div>
              )}
            </p>
          </Col>
          <Col md={6} className="second-half mb-5">
            <>
            <div className={type === 'contact' ? "phInput" : 'contact-us-phn'}>
              <PhoneInput
                onFocus={() => setIsFocused(true)}
                inputStyle={inputStyle}
                value={formik.values.Phone}
                countryCodeEditable={false}
                country={formik?.values?.phCountryCode || usercountryCode}
                onChange={(value: any, data: any) => {
                  formik.setFieldTouched("Phone", true);
                  const minimum =
                    data.format.match(/\./g).length - data.dialCode.length;
                  setMin(minimum);
                  formik.setFieldValue("phCountryCode", data.dialCode);
                  formik.setFieldValue("Phone", value);
                  formik.setFieldValue("phCountryCode", data?.dialCode);
                  formik.setFieldValue("countryName", data?.name);
                }}
              />
              {formik.errors?.Phone && formik.touched?.Phone && (
                <div className="invalid-feedback d-block">
                  {formik.errors.Phone}
                </div>
              )}
            </div>
            </>
          </Col>
          {((formik.values.countryName ===''&& country ==='India')||(formik.values.countryName === "India")) && (
            <Col xs={12} className="mb-5">
              <div>
                <Select
                  options={options}
                  name="Budget"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("Budget", selectedOptions?.value);
                  }}
                  styles={customStyles}
                  placeholder="Estimated Project Budget"
                />
              </div>
            </Col>
          )}
          <Col xs={12}>
            <p>
              <input
                type="text"
                name="Company"
                placeholder="Company name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Company}
              />
            </p>
          </Col>
          <Col>
            <p>
              <textarea
                name="Message"
                placeholder="Your Message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Message}
              />
              {formik.errors.Message && (
                <div className="invalid-feedback d-block">
                  {formik.errors.Message}
                </div>
              )}
            </p>
          </Col>
        </Row>
        <div className="fs-12 contact-form-check">
          <label>
            <input
              type="checkbox"
              onClick={(e: any) =>
                formik.setValues((prevValues:any) => ({
                  ...prevValues,
                  Newsletter: e.target.checked,
                }))
              }
            />{" "}
            Subscribe to Our Expertly Curated Newsletters.
          </label>
        </div>
        <Button type="submit">{loading ? "Sending..." : "Send Message"}</Button>
      </Form>
    </div>
  );
};

export default ContactForm;
